import { FieldValues, useForm } from 'react-hook-form';
import Input from '../../components/shared/inputs/input';
import useTranslation from '../../utils/hooks/useTranslation';
import { Text } from '../../components/shared/text';
import Select from '../../components/shared/inputs/select';
import { useGetSocieteAddressesQuery } from '../../store/auth/authApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Checkbox from '../../components/shared/inputs/checkbox';
import { useState } from 'react';
import Popup from '../../components/shared/popup';
import Button from '../../components/shared/button';
import * as yup from 'yup';
import useYupValidationResolver from '../../utils/hooks/useYupValidationResolver';
import { useStoreCommandMutation } from '../../store/command/commandApi';
import { addNotification } from '../../store/componentsSlice';
import { useStoreDevisMutation } from '../../store/devis/devisApi';
import { getApiUrl, getUniversFromUrl } from '../../project/helpers';
import { useCheckPaymentMutation } from '../../store/payment/paymentApi';
import { SvgLoader } from '../../components/svg';
import { shoppingCartApi } from '../../store/shoppingCart/shoppingCartApi';
import CGVPopupContent from '../../components/CGVPopupContent';
import Loader from '../../components/shared/loader';
import { RegexpPatterns } from '../../utils/defines';
import { APP_URL } from '../../project/defines';

const getValidationSchema = (isDevis: boolean, extCartWOPrefix:string, t: (a: string, b?: any) => string) => {
  const validationSchema = yup.object({
    reference: yup
      .string()
      .required('input.validations.required')
      .max(34, t('input.validations.max', { number: 34 }))
      .test(
        "is-after",
        t('input.validations.referenceCannotStartWith', {ExtCartWOPrefix: extCartWOPrefix ?? ""}),
        function (schema: any) {
          if (extCartWOPrefix && schema) {
            return !schema.toUpperCase().startsWith(extCartWOPrefix.toUpperCase());
          }
          return true;   
        }
      ),
    livraison_nom: yup.string().required('input.validations.required'), 
    livraison_adresse: yup
      .string()
      .required('input.validations.required')
      .max(30, t('input.validations.max', { number: 30 })),
    livraison_adresse_complement: yup
      .string()
      .nullable()
      .max(30, t('input.validations.max', { number: 30 })),
    livraison_code_postal: yup
      .string()
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'FR' || livraison_pays === 'ES' || livraison_pays === 'IT';
        },
        then: (schema: any) => schema.matches(/^(\d{5})$/, 'input.validations.code_postal'),
      })
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'AD';
        },
        then: (schema: any) => schema.matches(/^(AD\d{3})$/, 'input.validations.code_postal'),
      })
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'BE';
        },
        then: (schema: any) => schema.matches(/^(\d{4})$/, 'input.validations.code_postal'),
        otherwise: (schema) => schema.required('input.validations.required'),
      }),
    livraison_ville: yup
      .string()
      .required('input.validations.required')
      .max(30, t('input.validations.max', { number: 30 })),
    telephone: yup
      .string()
      .required('input.validations.phone')
      .matches(RegexpPatterns.phone, 'input.validations.phone')
      .max(15, 'input.validations.phone'),
  });
  const devisValidationSchema = yup.object({
    reference: yup
      .string()
      .required('input.validations.required')
      .max(34, t('input.validations.max', { number: 34 })),
    livraison_pays: yup.string().required('input.validations.required'),
    livraison_adresse: yup
      .string()
      .nullable()
      .max(30, t('input.validations.max', { number: 30 })),
    livraison_adresse_complement: yup
      .string()
      .nullable()
      .max(30, t('input.validations.max', { number: 30 })),
    livraison_code_postal: yup
      .string()
      .nullable()
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'FR' || livraison_pays === 'ES' || livraison_pays === 'IT';
        },
        then: (schema: any) => schema.matches(/^((\d{5})|^$)$/, 'input.validations.code_postal'),
      })
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'AD';
        },
        then: (schema: any) => schema.matches(/^((AD\d{3})|^$)$/, 'input.validations.code_postal'),
      })
      .when('livraison_pays', {
        is: (livraison_pays: string) => {
          return livraison_pays === 'BE';
        },
        then: (schema: any) => schema.matches(/^((\d{4})|^$)$/, 'input.validations.code_postal'),
      }),
    livraison_ville: yup
      .string()
      .nullable()
      .max(30, t('input.validations.max', { number: 30 })),
    telephone: yup
      .string()
      .nullable()
      .matches(RegexpPatterns.phone, 'input.validations.phone')
      .max(15, 'input.validations.phone'),
  });
  return isDevis ? devisValidationSchema : validationSchema;
};

const CommandPopupContent: React.FC<{
  cartData: any;
  onClose: () => void;
  isDevis?: boolean;
  setDevisPdfUrl?: (url: string) => void;
  setDevisSuccessPopupOpen?: (open: boolean) => void;
}> = ({ cartData, onClose, isDevis, setDevisPdfUrl, setDevisSuccessPopupOpen }) => { // NOSONAR
  const { t } = useTranslation();

  const universInfo = useAppSelector((state) => state.siteData?.universInfo);
  const resolver = useYupValidationResolver(getValidationSchema(isDevis!, universInfo?.ExtCartWOPrefix ?? "", t)); // NOSONAR

  const dispatch = useAppDispatch();
  const societe_uuid = useAppSelector((state) => state.auth.societe_uuid);

  const countries = useAppSelector((state) => state.siteData?.countries);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);

  const selectedCountry = useAppSelector((state) => state.siteData?.currentCountryUuid);
  const currentCountryCode = countries?.find((i) => i.uuid === selectedCountry)?.code ?? 'FR';

  const {
    control,
    getValues,
    //setValue,  // NOSONAR
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver,
    defaultValues: {
      livraison_adresse: '',
      livraison_adresse_complement: '',
      livraison_code_postal: '',
      livraison_nom: '',
      livraison_pays: currentCountryCode,
      livraison_ville: '',
      reference: '',
      telephone: '',
    },
    mode: 'onChange',
  });
  const [cGVOpen, setCGVOpen] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const {
    data: addresses,
    isLoading: isAddressesLoading,
    isFetching: isAddressesFetching,
  } = useGetSocieteAddressesQuery({ societe_uuid });
  const [triggerStoreCommand, { isLoading: isStoreCommandLoading }] = useStoreCommandMutation();
  const [triggerStoreDevis, { isLoading: isStoreDevisLoading }] = useStoreDevisMutation();
  const [triggerPaymentCheck, { isLoading: isCheckPaymentLoading }] = useCheckPaymentMutation();

  const [/*lastSelectValue*/, setLastSelectValue] = useState('');

  const onSubmit = async () => {
    const valid = await trigger();
    if (!valid) {
      console.log(errors);
      return;
    }
    const formValues = getValues();
    const requestBody = { ...formValues, univers_uuid: univers?.uuid, lignes_panier: cartData.lignes_panier };

    (isDevis ? triggerStoreDevis : triggerStoreCommand)({ data: requestBody })
      .then((response) => {
        (isDevis ? handleDevisResponse : handleCommandResponse)(response);
      })
      .catch((error) => {
        console.dir(error);
      });
  };
  const handleCommandResponse = async (response: any) => {
    dispatch(shoppingCartApi.util.invalidateTags(['CartList'])); // nice way to invalidate tags from another api
    if (response.error) {
      dispatch(
        addNotification({
          type: 'error',
          message: t('global.notifications.commandes.errorTitle'),
          description: response.error.data.error,
          duration: 5,
        })
      );
      return;
    }

    if (response.data.payment_url) {
      const paymentResult: any = await triggerPaymentCheck({ url: "/payment/" + response.data.uuid}); //url: response.data.payment_url });
      if (paymentResult.error) {
        dispatch(
          addNotification({
            type: 'error',
            message: t('global.notifications.commandes.errorTitle'),
            description: t(paymentResult.error.data.error),
            duration: 5,
          })
        );
      } else {
        document.write(paymentResult.data);
      }
    } else {
      onClose();
      dispatch(
        addNotification({
          type: 'success',
          message: t('panier.resultModal.title'),
          description: t('panier.resultModal.results.success.message'),
          duration: 5,
        })
      );
    }
  };
  const handleDevisResponse = (response: any) => {
    if (response.error) {
      dispatch(
        addNotification({
          type: 'error',
          message: t('global.notifications.devis.errorTitle'),
          description: response.error.data.error,
          duration: 5,
        })
      );
      return;
    }

    if (response.data?.file) {
      const url = getApiUrl(getUniversFromUrl()) + `/devis/download/` + response.data.uuid + '/' + response.data.file.replace('/', '-');
      setDevisPdfUrl?.(`${APP_URL.RedirectResource}?url=${escape(url)}`); // NOSONAR
      setDevisSuccessPopupOpen?.(true);
    }
    onClose();
  };

  const watchReference = watch('reference');

  return (
    <>
      <group direction="column" data-space="20" data-gap="20" data-scroll="" data-wrap="no" data-container="">
        <group data-gap="20" data-length="autofit">
          <Input
            isGroup
            control={control}
            name="reference"
            label={t(isDevis ? 'panier.devisModal.reference' : 'panier.commandeModal.reference') + ' *'}
            dataLength="fit"
          />

          <group>
            <Select
              isGroup
              autocomplete
              label={isDevis ? t('panier.devisModal.nom') : t('panier.commandeModal.nom') + ' *'}
              dataLength="fit"
              control={control}
              {...(isAddressesLoading || isAddressesFetching
                ? {
                    dropdownRender: () => ( // NOSONAR
                      <group data-space="10">
                        <Loader />
                      </group>
                    ),
                  }
                : null)}
              onSelect={(val) => {
                const address = addresses?.find((add) => add.uuid === val?.toString()); //nosonar
                setLastSelectValue(address?.nom ?? '');

                const formData = {
                  livraison_nom: address?.nom ?? '',
                  livraison_adresse: address?.adresse ?? '',
                  livraison_adresse_complement: address?.adresse_complement ?? '',
                  livraison_code_postal: address?.code_postal ?? '',
                  livraison_ville: address?.ville ?? '',
                  livraison_pays: address?.pays ?? '',
                  telephone: address?.telephone ?? '',
                  reference: watchReference,
                };
                reset(formData);
              }}
              name="livraison_nom"
              allowClear
              placeholder={t('panier.commandeModal.selectCreateLabel')}
              onClear={() => {
                setLastSelectValue('');
              }}
              onFocus={() => {
                const lastValue = getValues('livraison_nom');
                if (lastValue) {
                  setLastSelectValue(lastValue);
                }
              }}
              onBlur={(event) => {
              }}
              options={
                addresses
                  ? addresses.map((item) => {
                      return { value: item.uuid, text: item.nom };
                    })
                  : []
              }
            />
          </group>
        </group>

        <group data-gap="20" data-length="autofit">
          <Input
            isGroup
            control={control}
            name="livraison_adresse"
            label={isDevis ? t('panier.devisModal.adresse') : t('panier.commandeModal.adresse') + ' *'}
            dataLength="fit"
          />
          <Input isGroup control={control} name="livraison_adresse_complement" dataLength="fit" />
        </group>

        <group data-gap="10">
          <group data-length="100">
            <Input
              isGroup
              data-appearance="none"
              control={control}
              name="livraison_code_postal"
              onKeyDown={(e: any) => {
                return (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault(); // 'e' and '.' are banned now
              }}
              label={t('panier.commandeModal.codePostal') + (isDevis ? '' : ' *')}
            />
          </group>
          <group data-length="240">
            <Input
              isGroup
              dataLength="autofit"
              control={control}
              name="livraison_ville"
              label={t('panier.commandeModal.ville') + (isDevis ? '' : ' *')}
            />
          </group>
          <group data-length="240">
            <Select
              isGroup
              dataLength="autofit"
              onSelect={() => trigger()}
              label="panier.commandeModal.pays"
              control={control}
              name="livraison_pays"
              options={
                countries
                  ? countries.map((item) => {
                      return { value: item.code, text: item.nom };
                    })
                  : []
              }
            />
          </group>
        </group>
        <group data-length="350" data-gap="30">
          <Input
            isGroup
            control={control}
            name="telephone"
            label={t('panier.commandeModal.telephone') + (isDevis ? '' : ' *')}
            dataLength="fit"
          />
          <group>
            <Text accent>panier.commandeModal.obligatoire</Text>
          </group>

          {cGVOpen && (
            <Popup
              onClose={() => {
                setCGVOpen(false);
              }}
            >
              <CGVPopupContent reseau={cartData.lignes_panier[0].reseau_uuid} />
            </Popup>
          )}
        </group>
      </group>

      <group data-border="" data-space="10">
        {!isDevis && (
          <group data-space="10" data-align="center">
            <Checkbox
              classic
              label="panier.commandeModal.acceptCGV"
              checked={acceptConditions}
              onChange={(event) => {
                setAcceptConditions(event.target.checked);
              }}
            />
            (
            <text
              data-type="link"
              onClick={() => {
                setCGVOpen(true);
              }}
            >
              {t('panier.commandeModal.voirCGV')}
            </text>
            ) *
          </group>
        )}
        <group data-space="10">
          <Button
            disabled={
              !(isDevis ?? acceptConditions) || isStoreDevisLoading || isStoreCommandLoading || isCheckPaymentLoading
            }
            primary
            text={t(isDevis ? 'panier.devisModal.commandButton.title' : 'panier.commandeModal.commandButton.label')}
            onClick={onSubmit}
          >
            {(isStoreCommandLoading || isStoreDevisLoading) && (
              <icon>
                <SvgLoader />
              </icon>
            )}
          </Button>
        </group>
      </group>
    </>
  );
};

export default CommandPopupContent;
